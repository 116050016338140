@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #eee;
  min-width: 1250px;
}

.quotation-edit .mat-form-field-infix {
  width: 120px;
}

.field-has-suffix .mat-form-field-infix {
  width: 104px;
}

.btn-add {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.main-container {
  padding: 25px;
}

.main-box {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  margin-bottom: 16px;
  background-color: white;
  border-radius: 4px;
}

mat-list.icon-right .mat-list-item-content {
  flex-direction: row !important;
}

mat-list.higlight-list mat-list-item:hover {
  background-color: #f3f3f3;
}

.text-center {
  text-align: center;
}

/*
.ngx-contextmenu .dropdown-menu {
  @include mat-elevation(4);
  background-color: white;
  padding: 0;
}
.ngx-contextmenu li {
  display: block;
  border-top: 1px solid rgba(0,0,0,.12);
  font-size: inherit;
  line-height: 3em;
  height: 3em;
  padding: 0 16px;
}

.ngx-contextmenu li:hover {
  background: rgba(0,0,0,.12);
  color: #3f51b5;
  text-decoration: none;
}
.ngx-contextmenu li:first-child {
  border-top:none;
}
.ngx-contextmenu a:hover,
.ngx-contextmenu a:visited,
.ngx-contextmenu a:-webkit-any-link{
  color: inherit;
  text-decoration: none;
}*/
.ngx-contextmenu > ul {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 0;
  background: white;
}
.ngx-contextmenu li {
  display: block;
  line-height: 48px;
  height: 48px;
}
.ngx-contextmenu li:hover {
  background: rgba(255, 255, 255, 0.616);
}
.ngx-contextmenu a.hasSubMenu:before {
  padding: 0em 0.5em;
  content: "►";
}
.ngx-contextmenu .hasSubMenu:after {
  margin-left: 0.7em;
  margin-right: -0.7em;
}
.ngx-contextmenu a {
  text-decoration: none;
  display: inline-block;
  height: 100%;
  padding: 0px 20px;
  color: inherit;
}
.ngx-contextmenu span.passive {
  padding: 0 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.context-menu__icon {
  vertical-align: text-bottom;
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-right: 5px;
}

.form-field-no-padding {
  margin-bottom: -1.25em;
}
.form-field-no-padding .mat-form-field-infix {
  border-top: 0;
}

.machine-picture-select .mat-option {
  height: 70px !important;
}

.mat-menu-panel {
  min-height: 0 !important;
}